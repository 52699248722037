<template>
  <div class="p-4 bg-grey shadow-lg text-grey-light">
    <h2 class="text-4xl font-semibold">Contact Us</h2>
    <p class="py-4 text-lg">
      Address: Block-3 charmwood village Surajkund Faridabad Haryana 121009<br />
      Phone: (+91) 817-851-9994<br />
      Email: info@prashantinnovatio.com
    </p>
  </div>
</template>

<script></script>

<style scoped></style>
