import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@/assets/styles.scss";
import "@/assets/transitions.scss";
import App from "@/StaticPage.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@fortawesome/fontawesome-free/css/all.css";
import { router } from "@/router";

const app = createApp(App);
app.component(FontAwesomeIcon);
app.use(ElementPlus);
app.use(router);
app.mount("#app");
