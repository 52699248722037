<template>
  <div class="h-full">
    <img alt="Vue logo" src="logo.png" />
    <img alt="Vue logo" src="logo.png" />
    <img alt="Vue logo" src="logo.png" />
    <img alt="Vue logo" src="logo.png" />
    <img alt="Vue logo" src="logo.png" />
    <img alt="Vue logo" src="logo.png" />
    <img alt="Vue logo" src="logo.png" />
  </div>
</template>

<script></script>
