<template>
  <div class="relative h-[25rem] shadow-2xl">
    <div class="absolute top-0 left-0 w-1/2 h-1/2 bg-orange"></div>
    <div class="absolute top-0 right-0 w-1/2 h-1/2 bg-orange-light"></div>
    <div ref="boxRef" class="relative top-1/3 text-center z-10">
      <h1 class="text-6xl font-bold text-black">
        PRASHANT <span class="text-orange">INNOVATIO</span>
      </h1>
      <p class="text-xl text-grey mt-4">"INNOVATE. IMPLEMENT. IMPACT."</p>
    </div>
  </div>
</template>

<script setup>
import { gsap } from "gsap";
import { onMounted, ref } from "vue";

const boxRef = ref(null);
onMounted(() => {
  gsap.to(boxRef.value, { rotation: 360, x: 0, duration: 2 });
});
</script>

<style scoped></style>
