<template>
  <div class="py-16 px-8 bg-white text-black">
    <h2 class="text-4xl font-semibold text-black">About Us</h2>
    <div class="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
      <div v-for="(point, index) in aboutUsPoints" :key="index">
        <h3 class="text-xl font-bold text-orange text-center">
          {{ point.title }}
        </h3>
        <p class="text-grey-dark">{{ point.description }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
const aboutUsPoints = [
  {
    title: "Cutting-Edge Technology Solutions",
    description:
      "We specialize in designing innovative technology solutions that push the boundaries of what's possible, transforming industries and improving everyday life. Our work is at the forefront of technological advancements, creating products and services that not only meet but exceed expectations.",
  },
  {
    title: "Visionary Team",
    description:
      "Our team is composed of forward-thinking professionals who combine deep technical knowledge with a creative mindset. We believe in collaborating closely with our clients to truly understand their needs, ensuring that we deliver solutions that make a real difference.",
  },
  {
    title: "Sustainability at Core",
    description:
      "We are committed to sustainability in all that we do. From eco-friendly product designs to energy-efficient solutions, we ensure that our innovations have a positive impact on both the environment and the communities we serve.",
  },
  {
    title: "A Smarter, More Connected Future",
    description:
      "Join us on our mission to build a smarter, more connected future. We leverage the power of engineering, technology, and innovation to create solutions that bring people, businesses, and industries closer together, making life more efficient and interconnected.",
  },
];
</script>

<style scoped></style>
